



































import BaseDropdown from '@/components/BaseComponents/BaseDropdown/BaseDropdown.vue'
import { UnionServices } from '@/models/IUserUses'
import Vue, { PropType } from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'NewUiServiceDropdown',
  components: {
    BaseDropdown
  },
  props: {
    service: {
      type: String as PropType<UnionServices>,
      required: true
    },
    services: {
      type: Array as PropType<Array<UnionServices>>,
      default: () => []
    }
  },
  computed: {
    ...mapGetters('adService', {
      getServiceIconSrc: 'getServiceIconSrc',
      getServiceTitles: 'getServiceTitles'
    })
  },
  methods: {
    select (service: UnionServices): void {
      if (this.service !== service) {
        this.$emit('select', service)
      }
    }
  }
})
