





















import { PropType } from 'vue'

export default {
  name: 'NewUiCheckbox',
  props: {
    type: {
      type: [Number, String] as PropType<'1' | '2' | 1 | 2>,
      default: '1'
    },
    checked: {
      type: Boolean
    },
    indeterminate: {
      type: Boolean
    },
    span: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleChange (): void {
      !this.disabled && this.$emit('change', !this.checked)
    }
  }
}
