










































import BasePagePreloader from '@/components/BasePagePreloader.vue'
import NewUiServiceDropdown from '@/components/NewUIComponents/NewUiServiceDropdown.vue'
import { IStateIntegrationData, IStateIntegrationDataWithPages } from '@/models/Integrations/IStateIntegrations'
import IUserUses, { UnionServices } from '@/models/IUserUses'
import { IPagination } from '@/services/api/AffiliateService'
import PostBoostingService from '@/services/api/PostBoostingService'
import type { Filter } from '@/utils/Filter'
import AutoPostBoostingTable from '@/views/AutoPostBoosting/AutoPostBoostingTable.vue'
import Jsona from 'jsona'
import Vue, { PropType } from 'vue'
import { mapActions, mapGetters } from 'vuex'

const integrationsGetters = ['getSnapchat', 'getFacebook', 'getTiktok', 'getLinkedin', 'getGoogle', 'getTwitter']
const integrationsGettersAsService = integrationsGetters.map((each) => each.toLowerCase().slice(3))
type ServiceData = IStateIntegrationDataWithPages | IStateIntegrationData | null

const jsona = new Jsona()

export default Vue.extend({
  name: 'AutoPostBoosting',
  components: {
    AutoPostBoostingTable,
    BasePagePreloader,
    NewUiServiceDropdown
  },
  data: (): {
    checkingConnections: boolean,
    loading: boolean,
    rules: Array<unknown> | null,
    pagination: IPagination | null
  } => (
    {
      checkingConnections: true,
      loading: true,
      rules: null,
      pagination: null
    }
  ),
  props: {
    service: {
      type: String as PropType<UnionServices>,
      required: true
    }
  },
  computed: {
    ...mapGetters('integrations', integrationsGetters),
    ...mapGetters('adService', { getServiceTitles: 'getServiceTitles' }),
    ...mapGetters(['get_sidebar_views']),
    services (): Array<UnionServices> {
      return (
        Object.keys(this.getServiceTitles as Record<UnionServices, string>) as Array<UnionServices>
      )
        .filter(_ => (
          this.get_sidebar_views as IUserUses
        ).post_boosting[_])
    }
  },
  methods: {
    ...mapActions('integrations', {
      loadAdAccounts: 'load_ad_accounts',
      getServicePages: 'getServicePages'
    }),
    ...mapActions('notifications', {
      addNotification: 'addNotification'
    }),
    ...mapActions('adService', {
      setService: 'setService'
    }),
    checkConnections (): void {
      const index = integrationsGettersAsService.indexOf(this.service)
      let params = {}

      if (index) {
        const serviceData = this[integrationsGetters[index]]

        if (serviceData) {
          const hasAdAccounts = this.hasActiveAdAccount(serviceData)
          const hasPages = this.hasPublishedPage(serviceData)

          if (hasAdAccounts) {
            this.checkingConnections = false
            return
          }
          params = {
            hasAdAccounts,
            hasPages
          }
        }
      }
      this.$router.push({
        name: 'PostBoostingWithoutConnect',
        params
      })
    },
    hasActiveAdAccount (serviceData: ServiceData): boolean {
      return !!serviceData.data.find(account => account.status === 1000)
    },
    hasPublishedPage (serviceData: IStateIntegrationDataWithPages): boolean {
      return !!(
        serviceData &&
        serviceData.pages &&
        serviceData.pages.length &&
        serviceData.pages.find(page => page.attributes.is_published === true)
      )
    },
    selectService (service: UnionServices): void {
      this.$router.push({ name: 'PostBoostingDev', params: { service } })
    },
    toNewBoostingRule (): void {
      this.$router.push({ name: 'PostBoostingNew' })
    },
    async loadRules (service: UnionServices, queryParams: Record<string, unknown>, filter: Filter): Promise<void> {
      try {
        const axiosResponse = await PostBoostingService.getPostBoostingRules(service, queryParams, filter)
        this.rules = jsona.deserialize(axiosResponse.data)
        this.pagination = (axiosResponse.data as { meta: { pagination: IPagination } })?.meta?.pagination || null
      } catch (error) {
        return this.addNotification({
          type: 'danger',
          body: 'Something went wrong! Please retry later'
        })
      }
    }
  },
  created (): void {
    this.setService(this.service)
    Promise.allSettled([
      this.loadAdAccounts({ service: this.service }),
      this.getServicePages(this.service)
    ]).then((promises) => {
      if (promises.find(promise => promise.status === 'rejected')) {
        return this.addNotification({
          type: 'danger',
          body: 'Something went wrong! Please retry later'
        })
      }
      this.checkConnections()
      this.loadRules(this.service, {
        per_page: 15
      }).then(() => {
        this.loading = false
      })
    })
  }
})
