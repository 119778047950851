
















































































































































































































































import BaseInputVuelidate from '@/components/BaseInputVuelidate.vue'
import BasePagePreloader from '@/components/BasePagePreloader.vue'
import NewUiCheckbox from '@/components/NewUIComponents/NewUiCheckbox.vue'
import NewUiPagination from '@/components/NewUIComponents/NewUiPagination.vue'
import NewUiSwitcher from '@/components/NewUIComponents/NewUiSwitcher.vue'
import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'
import { UnionServices } from '@/models/IUserUses'
import { IPagination } from '@/services/api/AffiliateService'
import RuleDeleteModal from '@/components/strategies/modal/RuleDeleteModal.vue'
import PostBoostingService from '@/services/api/PostBoostingService'
import { Filter } from '@/utils/Filter'
import Vue, { PropType } from 'vue'

export type AutoPostBoostingRule = {
  ad_account_id: number,
  boosted_posts_count: number,
  conditions: unknown,
  description: string,
  folder_id: unknown | null,
  id: string,
  name: string,
  page_id: number,
  platform: string,
  post_types: Array<string>,
  service: UnionServices,
  settings_source_id: number,
  status: string,
  type: 'post_boosting_rules',
  user_id: number
}

export default Vue.extend({
  name: 'AutoPostBoostingTable',
  components: { BasePagePreloader, NewUiPagination, BaseInputVuelidate, NewUiSwitcher, NewUiCheckbox, VSelect, RuleDeleteModal },
  data: (): {
    sort: 'id' | '-id' | undefined,
    selectedIds: Array<string>,
    toDeleteRule: string,
    refSearchByName: string
    filter: Filter
  } => ({
    sort: undefined,
    selectedIds: [],
    toDeleteRule: '',
    refSearchByName: '',
    filter: new Filter([])
  }),
  props: {
    service: {
      type: String as PropType<UnionServices>,
      required: true
    },
    rules: {
      type: Array as PropType<Array<AutoPostBoostingRule>>,
      default: null
    },
    pagination: {
      type: Object as PropType<IPagination>,
      default: null
    }
  },
  watch: {
    refSearchByName (value: string): void {
      this.refSearchByName = value
      this.filter.set(value ? [{
        field: 'NAME',
        predicate: 'CONTAINS',
        value: value
      }] : [])
      this.loadRules()
    }
  },
  methods: {
    sortByField (field: string): void {
      if (this.sort === field) {
        this.sort = '-' + field
      } else if (this.sort === '-' + field) {
        this.sort = undefined
      } else {
        this.sort = field
      }
      this.loadRules()
    },
    deselect (): void {
      this.selectedIds = []
    },
    selectAll (): void {
      if (this.selectedIds.length >= this.rules.length) {
        return this.deselect()
      }
      this.selectedIds = this.rules.map(_ => _.id)
    },
    select (rule: AutoPostBoostingRule): void {
      if (this.selectedIds.includes(rule.id)) {
        this.selectedIds = this.selectedIds.filter(_ => _ !== rule.id)
      } else {
        this.selectedIds.push(rule.id)
      }
    },
    setPerPage (perPage: number): void {
      if (this.pagination.per_page === perPage) {
        this.pagination.per_page = perPage
      }
    },
    changePage (page: number): void {
      if (this.pagination.current_page !== page) {
        this.pagination.current_page = page
        this.loadRules()
      }
    },
    loadRules (): void {
      this.deselect()
      this.$emit('loadRules', [{
        page: this.pagination?.current_page,
        sort: this.sort,
        per_page: this.pagination?.per_page
      }, this.filter])
    },
    preDeleteRule (id: string): void {
      this.toDeleteRule = id
      this.$modal.show('delete-rule-modal')
    },
    deleteRule (id: string): void {
      PostBoostingService.deletePostBoostingRule(this.service, id)
        .then(() => {
          this.loadRules()
          this.$modal.hide('delete-rule-modal')
        })
    }
  }
})

